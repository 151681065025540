/* Main Landing Page Container - Controls vertical scrolling */
.landing-container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  /* scroll-snap-type: y mandatory; */
  scroll-behavior: smooth;
}

/* Common styles for all full-height sections */
.landing-section {
  height: 100vh;
  /* scroll-snap-align: start; */
  position: relative;
}

/* =================== FIRST SECTION - SIGN UP =================== */
/* Main container for the sign up section */
.sign-up-container {
  display: flex;
  min-height: 100vh;
}

/* Left side - Sign up form area */
.sign-up-left {
  flex: 1;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

/* Right side - Background image and value proposition */
.sign-up-right {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

/* Background image container */
.background-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Blue overlay on background image */
.background-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 120, 240, 1);
}

/* Content container for sign up form */
.sign-up-content {
  max-width: 600px;
  width: 100%;
  text-align: center;
}

/* Logo styling */
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.logo-image {
  max-width: 300px;
  width: 100%;
  height: auto;
  object-fit: contain;
}

/* CTA (Call to Action) section styling */
.cta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.cta-container h2 {
  font-size: 2rem;
  color: #202124;
  margin: 0;
}

.cta-container p {
  font-size: 1.3rem;
  color: #5f6368;
  margin: 0;
}

/* Button styling */
.cta-button {
  background-color: #1a73e8;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 50px;
  font-size: 1.25rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.3rem;;
}

.cta-button:hover {
  background-color: #1557b0;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Form styles */
.sign-up-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
  margin-top: 2rem;
}

.email-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background-color: #f8f9fa;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.email-display span {
  color: #202124;
  font-weight: 500;
}

/* Form input styling */
.form-input {
  padding: 0.75rem;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.2s;
}

.form-input:focus {
  border-color: #1a73e8;
  outline: none;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.2);
}

/* Submit button styling */
.submit-button {
  background-color: #1a73e8;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover:not(:disabled) {
  background-color: #1557b0;
}

.submit-button:disabled {
  background-color: #dadce0;
  cursor: not-allowed;
}

/* Text button styling */
.text-button {
  background: none;
  border: none;
  color: #1a73e8;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
}

.text-button:hover:not(:disabled) {
  text-decoration: underline;
}

/* Message styling */
.error-message {
  color: #d93025;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: #fce8e6;
  margin-bottom: 1rem;
}

.success-message {
  color: #1e8e3e;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: #e6f4ea;
  margin-bottom: 1rem;
}

/* Value proposition styling */
.value-proposition {
  position: relative;
  z-index: 1;
  background-color: rgba(43, 100, 244, 0);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0);
  max-width: 800;
  width: 100%;
}

.value-proposition h3 {
  color: #ffffff;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.value-proposition ul {
  list-style: none;
  padding: 0;
}

.value-proposition li {
  color: #ffffff;
  margin-bottom: 1rem;
  padding-left: 2.5rem;
  position: relative;
  font-size: 3.5rem;
  font-weight: bold;
}

/* =================== SECOND SECTION - WHY ATOMWISSEN =================== */

/* Second page container */
.why-atomwissen {
  background-color: #e5effa6f;
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Content container for the second section */
.section-content {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

/* Section title styling */
.section-title {
  text-align: center;
  color: #202124;
  font-size: 2.8rem;
  margin-bottom: 3rem;
}

/* Benefits grid layout */
.benefits-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 2.5rem;                          
  padding: 2rem;
  max-width: 1400px;                    
  margin: 0 auto;
}

/* Individual benefit card styling */
.benefit-card {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.benefit-card:hover {
  transform: translateY(-5px);
}

.benefit-icon {
  margin-bottom: 0rem;
}

.benefit-card h3 {
  color: #202124;
  margin-bottom: 0.5rem;
  font-size: 1.8rem;
}

.benefit-card p {
  color: #5f6368;
  font-size: 1.2rem;
  line-height: 1.5;
}

/* =================== RESPONSIVE DESIGN =================== */

@media (max-width: 768px) {
  /* First section responsive */
  .sign-up-container {
    flex-direction: column;
  }
  
  .sign-up-right {
    display: none;
  }
  
  .sign-up-content {
    padding: 1rem;
  }
  
  .cta-container h2 {
    font-size: 1.75rem;
  }
  
  .cta-button {
    width: 100%;
    padding: 0.875rem 1.5rem;
  }

  /* Second section responsive */
  .section-title {
    font-size: 2rem;
    padding: 0 1rem;
  }

  .benefits-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 1rem;
  }
}