/* Question.css */

.question-container {
  min-height: 100vh;
  background-color: #f8fafc;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
}

/* Header Styles */
.home-header-wrapper {
  background-color: rgba(229, 239, 250, 0.6);
  padding: 0.5rem 0.25rem 0;
  width: 100%;
  border-bottom: 1px solid #e2e8f0;
}

.home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  width: 100%;
}

.logo-section {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.logo-image {
  max-width: 260px;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.header-subtitle {
  font-size: 1.0rem;
  color: #29335C;
  font-weight: 500;
}

.return-home-button {
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: #29335C;
  border: 2px solid #29335C;
  border-radius: 0.2rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.return-home-button:hover {
  background-color: #29335C;
  color: white;
}

/* Content Styles */
.content-wrapper {
  max-width: 800px;
  width: 100%;
  margin: 2rem auto;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 0;
}

.question-header {
  background-color: #29335C;
  color: white;
  padding: 1.5rem;
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}

.options-container {
  padding: 0 1.5rem 1.5rem 1.5rem;
}

.option-button {
  width: 100%;
  text-align: left;
  padding: 1rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid #e2e8f0;
  border-radius: 0.75rem;
  background-color: white;
  transition: all 0.2s ease;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  cursor: pointer;
}

.option-button:hover:not(:disabled) {
  border-color: #29335C;
  background-color: #f8fafc;
}

.option-button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.option-radio {
  margin-top: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #cbd5e1;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.option-radio:checked {
  border-color: #29335C;
  background-color: #29335C;
}

/* Result Styles */
.result-container {
  margin-top: 2rem;
  padding: 1.5rem;
  border-top: 1px solid #e2e8f0;
  background-color: white;
}

.result-header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.result-icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.correct-answer {
  font-weight: 600;
  color: #16a34a;
}

.incorrect-answer {
  font-weight: 600;
  color: #dc2626;
}

.explanation-box {
  background-color: #f8fafc;
  border-radius: 0.75rem;
  padding: 1.25rem;
  margin-bottom: 1.5rem;
}

.concept-box {
  background-color: #eff6ff;
  border-radius: 0.75rem;
  padding: 1.25rem;
  border-left: 4px solid #2563eb;
}

.section-title {
  color: #29335C;
  font-weight: 600;
  margin-bottom: 0.75rem;
  font-size: 1.1rem;
}

.section-content {
  color: #475569;
  line-height: 1.6;
}

/* Button Styles */
.action-button {
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 2rem auto 1rem;
  padding: 0.875rem 1.5rem;
  background-color: #29335C;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.action-button:hover:not(:disabled) {
  background-color: #1e2642;
}

.action-button:disabled {
  background-color: #cbd5e1;
  cursor: not-allowed;
}

/* Loading and Error States */
.status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  text-align: center;
  padding: 2rem;
}

.loading-text {
  color: #29335C;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.error-text {
  color: #dc2626;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.retry-button {
  padding: 0.75rem 1.5rem;
  background-color: #29335C;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.retry-button:hover {
  background-color: #1e2642;
}

/* Formula and Table Container Styles */
.question-formula-wrapper,
.feedback-formula-wrapper {
  padding: 0 1.5rem;
  margin: 1rem 0;
}

.question-table-wrapper,
.feedback-table-wrapper {
  margin: 1.5rem auto;
  max-width: 100%;
  overflow-x: hidden;
}

/* Table wrapper in questions should stretch to fill available space */
.content-wrapper {
  max-width: 800px;
  width: 100%;
  margin: 2rem auto;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 0;
}

/* Add some helper text for mobile users */
.table-scroll-hint {
  text-align: center;
  color: #64748b;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  font-style: italic;
  display: none;
}

@media (max-width: 640px) {
  .table-scroll-hint {
    display: block;
  }
}

/* Loading Component Styles */
.component-loading {
  padding: 1rem;
  margin: 1rem;
  background-color: #f1f5f9;
  border-radius: 0.5rem;
  text-align: center;
  color: #64748b;
}

/* Make sure formulas don't overflow on mobile */
@media (max-width: 640px) {
  .question-formula-wrapper,
  .feedback-formula-wrapper,
  .question-table-wrapper,
  .feedback-table-wrapper {
    padding: 0.5rem;
    margin: 1rem 0.5rem;
  }
  
  /* Adjust font size for formulas on small screens */
  .formula-content.display-mode {
    font-size: 1rem;
  }
  
  /* Home header adjustments for mobile */
  .home-header {
    padding: 0 0.5rem;
    flex-direction: column;
    text-align: center;
  }
  
  .logo-section {
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .return-home-button {
    margin-bottom: 0.5rem;
  }
}