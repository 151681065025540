/* src/components/Login.css */

.login-wrapper {
  position: relative;
  min-height: 100vh;
  width: 100%;
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Semi-transparent overlay */
.login-wrapper .background-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(229, 239, 250, 0.6)
}

.login-container {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
}

.login-box {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 400px;
}

/* Rest of your existing Login.css styles remain the same */
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.logo-image {
  max-width: 300px;
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-top: 1.0rem;
  margin-bottom: 0.5rem;
}

.login-content h2 {
  color: #202124;
  margin-bottom: 0.5rem;
  text-align: center;
}

.login-content p {
  color: #5f6368;
  margin-bottom: 2rem;
  text-align: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-input {
  padding: 0.75rem;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.form-input:focus {
  border-color: #1a73e8;
  outline: none;
}

.submit-button {
  background-color: #1a73e8;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 1rem;
}

.submit-button:hover:not(:disabled) {
  background-color: #1557b0;
}

.submit-button:disabled {
  background-color: #dadce0;
  cursor: not-allowed;
}

.text-button {
  background: none;
  border: none;
  color: #1a73e8;
  cursor: pointer;
  text-decoration: none;
  font-size: inherit;
}

.text-button:hover {
  text-decoration: underline;
}

.error-message {
  color: #d93025;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: #fce8e6;
}

.forgot-password {
  text-align: right;
  margin-top: -0.5rem;
}

.login-footer {
  margin-top: 2rem;
  text-align: center;
  color: #5f6368;
}

.verification-section {
  margin: 15px 0;
  text-align: center;
}

.resend-button {
  background-color: #ffffff;
  color: #1a73e8;
  border: 1px solid #1a73e8;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.resend-button:hover {
  background-color: #f8f9fa;
}

.resend-button:disabled {
  background-color: #f1f3f4;
  border-color: #dadce0;
  color: #80868b;
  cursor: not-allowed;
}

.verification-message {
  margin-top: 10px;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
}

.verification-message.success {
  background-color: #e6f4ea;
  color: #137333;
  border: 1px solid #137333;
}

.verification-message.error {
  background-color: #fce8e6;
  color: #d93025;
  border: 1px solid #d93025;
}