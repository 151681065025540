/* Home.css */

/* Container */
.home-container {
  min-height: 100vh;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Header */
.home-header-wrapper {
  background-color: rgba(229, 239, 250, 0.6);
  padding: 0.5rem 0.25rem 0rem;
  margin-bottom: 0.5rem;
  width: 100%;
  border-bottom: 1px solid #e2e8f0;
}

.home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  width: 100%;
}

.logo-image {
  max-width: 260px;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.logo-section {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.header-subtitle {
  font-size: 1.0rem;
  color: #29335C;
  font-weight: 500;
}

/* User Menu */
.user-menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.user-menu-button:hover {
  transform: scale(1.1);
}

.user-menu-dropdown {
  position: absolute;
  right: 0;
  margin-top: 0.5rem;
  width: 12rem;
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  z-index: 50;
}

.user-menu-item {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: left;
  color: #29335C;
  transition: background-color 0.2s ease;
}

.user-menu-item:hover {
  background-color: #f8fafc;
}

/* Error Message */
.error-message {
  max-width: 400px;
  margin: 0 auto 1.5rem;
  padding: 0.5rem;
  background-color: #fee2e2;  
  font-size: 1.25rem;
  font-weight: 800;
  color: #dc2626;
  border-radius: 0.2rem;
  text-align: center;
  border: 1px solid #fecaca;
}

/* Main Content Layout */
.main-content {
  display: grid;
  grid-template-columns: 600px 1fr;
  gap: 1rem;
  padding-right: 1rem;
  flex: 1;
  height: calc(100vh - 80px);
  overflow: hidden;
}

/* Topics List */
.topics-container {
  padding-left: 1rem;
  max-width: 100%;
  padding-right: 0;
  height: 100%;
  overflow-y: auto;
}

/* Topic Item */
.topic-item {
  border: 1.5px solid #e2e8f0;
  border-radius: 0.2rem;
  margin-bottom: 0.4rem;
  background-color: white;
  max-width: 500px;
  cursor: pointer;
  transition: border-color 0.2s ease;
}

.topic-item:hover {
  border-color: #29335C;
}

.topic-header {
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}

.topic-title {
  padding: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #29335C;
  display: flex;
  align-items: center;
}

/* Topic Details Container */
.topic-details-container {
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.2rem;
  padding: 2rem;
  height: 99%;
  margin-left: -5.8rem;
  margin-right: -0.2rem;
  overflow-y: auto;
}

/* Topic Details */
.topic-details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.section-title {
  font-size: 1.25rem;
  color: #29335C;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

/* Assisted Mastery Section */
.assisted-mastery-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.mastery-cta {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

.start-mastery-button {
  padding: 1rem 2rem;
  background-color: #29335C;
  color: white;
  border: 2px solid #29335C;
  border-radius: 0.2rem;
  font-weight: 500;
  font-size: 1.1rem;
  transition: all 0.2s ease;
  cursor: pointer;
}

.start-mastery-button:hover {
  background-color: white;
  color: #29335C;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.mastery-progress {
  height: 250px;
  width: 100%;
  padding: 1rem;
  background-color: #f8fafc;
  border-radius: 0.2rem;
}

/* Divider */
.divider {
  height: 1px;
  background-color: #e2e8f0;
  width: 100%;
  margin: 1rem 0;
}

/* Self-Paced Section */
.modules-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.module-item {
  border: 1.5px solid #e2e8f0;
  border-radius: 0.2rem;
  background-color: white;
  transition: border-color 0.2s ease;
}

.module-item:hover {
  border-color: #29335C;
}

.module-header {
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}

.module-loading,
.module-error,
.no-modules {
  padding: 1rem;
  text-align: center;
  color: #64748b;
  background-color: #f8fafc;
  border-radius: 0.2rem;
  margin: 0.5rem 0;
}

.module-error {
  color: #dc2626;
  background-color: #fee2e2;
}

/* Buttons */
.subscribe-button, 
.practice-button, 
.module-practice-button {
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
  font-weight: 500;
  transition: all 0.2s ease;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.subscribe-button,
.module-practice-button {
  background-color: white;
  color: #29335C;
  border: 2px solid #29335C;
}

.subscribe-button:hover,
.module-practice-button:hover {
  background-color: #29335C;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.practice-button {
  background-color: #29335C;
  color: white;
  border: 2px solid #29335C;
}

.practice-button:hover {
  background-color: white;
  color: #29335C;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Placeholder */
.topic-details-placeholder {
  text-align: center;
  color: #64748b;
  padding: 2rem;
}

.topic-details-placeholder p {
  margin-top: 1rem;
  font-size: 1.1rem;
}

/* Loading State */
.loading-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.loading-text {
  color: #29335C;
  font-size: 1.25rem;
  font-weight: 500;
}