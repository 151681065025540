/* src/components/ResetPassword.css */

.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f7fa;
  padding: 1rem;
}

.reset-password-box {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 400px;
}

.reset-password-content {
  text-align: center;
}

.reset-password-content h1 {
  color: #1a73e8;
  margin-bottom: 1rem;
}

.reset-password-content h2 {
  color: #202124;
  margin-bottom: 0.5rem;
}

.reset-password-content p {
  color: #5f6368;
  margin-bottom: 2rem;
}

.reset-password-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-input {
  padding: 0.75rem;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.form-input:focus {
  border-color: #1a73e8;
  outline: none;
}

.message-box {
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.message-box.success {
  background-color: #e6f4ea;
  color: #1e8e3e;
}

.message-box.error {
  background-color: #fce8e6;
  color: #d93025;
}

.submit-button {
  background-color: #1a73e8;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover:not(:disabled) {
  background-color: #1557b0;
}

.submit-button:disabled {
  background-color: #dadce0;
  cursor: not-allowed;
}

.back-link {
  color: #1a73e8;
  text-decoration: none;
  margin-top: 1rem;
  display: inline-block;
}

.back-link:hover {
  text-decoration: underline;
}

.request-link {
  color: #1a73e8;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  border: 1px solid #1a73e8;
  border-radius: 4px;
  display: inline-block;
  margin-top: 1rem;
  transition: all 0.2s;
}

.request-link:hover {
  background-color: #1a73e8;
  color: white;
}