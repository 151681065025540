/* src/components/VerifyEmail.css */

.verify-email-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f7fa;
  padding: 1rem;
}

.verify-email-box {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 400px;
}

.verify-email-content {
  text-align: center;
}

.verify-email-content h1 {
  color: #1a73e8;
  margin-bottom: 1rem;
}

.verify-email-content h2 {
  color: #202124;
  margin-bottom: 2rem;
}

.status-container {
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.status-container.success {
  background-color: #e6f4ea;
  color: #1e8e3e;
}

.status-container.error {
  background-color: #fce8e6;
  color: #d93025;
}

.status-container.info {
  background-color: #e8f0fe;
  color: #1a73e8;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.status-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.status-icon {
  font-size: 2rem;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.primary-button, .secondary-button {
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.2s;
}

.primary-button {
  background-color: #1a73e8;
  color: white;
}

.primary-button:hover {
  background-color: #1557b0;
}

.secondary-button {
  border: 1px solid #1a73e8;
  color: #1a73e8;
}

.secondary-button:hover {
  background-color: #e8f0fe;
}